.header {
  padding: 10px 20px;
  background-color: #f8f9fa !important;
}

.header .navbar {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.navbar-brand img {
  width: 200px;
}

.collapse {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-nav {
  display: flex;
  list-style-type: none;
  justify-content: center;
  align-items: center;
  flex-direction: row !important;
}

.navbar-nav li {
  padding: 0px 20px;
}

.navbar-nav li.active a {
  color: rgba(0, 0, 0, 0.8);
  font-weight: bold;
}

.navbar-nav li a {
  color: rgba(0, 0, 0, 0.5);
  text-decoration: none;
  font-size: 16px;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
}

.dropdown-menu {
  position: absolute;
  top: 0;
  right: 0;
  display: none;
}

.user-details {
  background-color: #f0f2f5;
}

.user-details p {
  margin-bottom: 1rem;
}

.marking_box_container {
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
}

.marking_box_container > div {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.verification_actions{
  flex-direction: column;
}

.verification_actions button, .verification_actions p {
  width: 50%;
  height: 40px;
  padding: 6.4px 15px;
  font-size: 16px;
  border-radius: 2px;
  margin-top: 20px;
}

.verification_actions button{
  border: none;
  color: #fff;
  cursor: pointer;
}

.verification_actions button.success{
  background-color: green;
}

.verification_actions button.error{
  background-color: red;
}

.verification_actions p span{
  color: #fff;
  background-color: red;
  padding: 10px;
  border-radius: 4px;
}