.login-container {
  height: 100vh;
}

.login-card {
  position: relative;
  top: 50%;
  left: 50%;
}

.login-form {
  border: 1px solid;
  border-radius: 10px;
  box-shadow: 5px 5px 5px #aaaaaa;
}

.logo {
  text-align: center;
}

.logo > img {
  padding: 20px;
  width: 200px;
}
@import url("https://netdna.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css");
