.pricingqa-data {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pricingqa-data > div {
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 600px;
  overflow-y: auto;
}

.pricingqa-data .button-container {
  max-width: 500px;
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.pricingqa-data form {
  max-width: 500px;
  width: 100%;
  margin: 50px 0px;
}

.pricingqa-data .button-container button {
  border: none;
  background-color: green;
  color: #fff;
  padding: 5px 10px;
}

/* .form-action-header {
  display: flex;
  color: red;
} */

/* .form-action-header>div{
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid red;
  border-radius: 50%;
  margin: 0px 5px;
  aspect-ratio: 1/1;
} */

.form-action-header .delete-btn {
  cursor: pointer;
}

label {
  margin-top: 20px;
}

select,
input {
  width: 100%;
  overflow: auto;
}
