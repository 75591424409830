@import url(https://netdna.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css);
.mode-switch {
  display: flex;
  align-items: center;
  margin: 0px 10px !important;
}

.mode-switch p {
  padding: 0px 10px;
  margin: auto !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: auto !important;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.header {
  padding: 10px 20px;
  background-color: #f8f9fa !important;
}

.header .navbar {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.navbar-brand img {
  width: 200px;
}

.collapse {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-nav {
  display: flex;
  list-style-type: none;
  justify-content: center;
  align-items: center;
  flex-direction: row !important;
}

.navbar-nav li {
  padding: 0px 20px;
}

.navbar-nav li.active a {
  color: rgba(0, 0, 0, 0.8);
  font-weight: bold;
}

.navbar-nav li a {
  color: rgba(0, 0, 0, 0.5);
  text-decoration: none;
  font-size: 16px;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
}

.dropdown-menu {
  position: absolute;
  top: 0;
  right: 0;
  display: none;
}

.user-details {
  background-color: #f0f2f5;
}

.user-details p {
  margin-bottom: 1rem;
}

.marking_box_container {
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
}

.marking_box_container > div {
  flex: 1 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.verification_actions{
  flex-direction: column;
}

.verification_actions button, .verification_actions p {
  width: 50%;
  height: 40px;
  padding: 6.4px 15px;
  font-size: 16px;
  border-radius: 2px;
  margin-top: 20px;
}

.verification_actions button{
  border: none;
  color: #fff;
  cursor: pointer;
}

.verification_actions button.success{
  background-color: green;
}

.verification_actions button.error{
  background-color: red;
}

.verification_actions p span{
  color: #fff;
  background-color: red;
  padding: 10px;
  border-radius: 4px;
}
.login-container{height:100vh}.login-card{position:relative;top:50%;left:50%}.login-form{border:1px solid;border-radius:10px;box-shadow:5px 5px 5px #aaa}.logo{text-align:center}.logo>img{padding:20px;width:200px}
.login-container {
  height: 100vh;
}

.login-card {
  position: relative;
  top: 35%;
}

.login-form {
  border: 1px solid;
  border-radius: 10px;
  box-shadow: 5px 5px 5px #aaaaaa;
}

.logo {
  text-align: center;
}

.logo > img {
  padding: 20px;
  width: 200px;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
}

.options-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.options-container > * {
  /* flex: 1; */
  margin: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: #aaa; */
  /* height: 100%; */
}

.main-container {
  display: flex;
  align-items: stretch;
  width: 100%;
}

.partition1 {
  width: 100%;
}

.partition2 {
  display: flex;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.sample-images {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0px 0px 0px;
}

.sample-images img {
  margin: 10px auto;
}

.pricingqa-data {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pricingqa-data > div {
  position: relative;
  flex: 1 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 600px;
  overflow-y: auto;
}

.pricingqa-data .button-container {
  max-width: 500px;
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.pricingqa-data form {
  max-width: 500px;
  width: 100%;
  margin: 50px 0px;
}

.pricingqa-data .button-container button {
  border: none;
  background-color: green;
  color: #fff;
  padding: 5px 10px;
}

.form-action-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.actions {
  display: flex;
  color: red;
  border: none;
}

.form-action-header .actions > div {
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid red;
  border-radius: 50%;
  margin: 0px 5px;
  aspect-ratio: 1/1;
}

.form-action-header .info {
  font-size: 18px;
  font-weight: 600;
}

.form-action-header .info div {
  text-align: end;
}

.form-action-header .delete-btn {
  cursor: pointer;
}

label {
  margin-top: 20px;
}

select,
input {
  width: 100%;
  overflow: auto;
}

.link-btn {
  color: blue;
  cursor: pointer;
}

.pricingqa-data {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pricingqa-data > div {
  position: relative;
  flex: 1 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 600px;
  overflow-y: auto;
}

.pricingqa-data .button-container {
  max-width: 500px;
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.pricingqa-data form {
  max-width: 500px;
  width: 100%;
  margin: 50px 0px;
}

.pricingqa-data .button-container button {
  border: none;
  background-color: green;
  color: #fff;
  padding: 5px 10px;
}

/* .form-action-header{
  display: flex;
  color: red;
}

.form-action-header>div{
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid red;
  border-radius: 50%;
  margin: 0px 5px;
  aspect-ratio: 1/1;
}

.form-action-header .delete-btn{
  cursor: pointer;
} */

label {
  margin-top: 20px;
}

select,
input {
  width: 100%;
  overflow: auto;
}

.pricingqa-data {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pricingqa-data > div {
  position: relative;
  flex: 1 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 600px;
  overflow-y: auto;
}

.pricingqa-data .button-container {
  max-width: 500px;
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.pricingqa-data form {
  max-width: 500px;
  width: 100%;
  margin: 50px 0px;
}

.pricingqa-data .button-container button {
  border: none;
  background-color: green;
  color: #fff;
  padding: 5px 10px;
}

/* .form-action-header {
  display: flex;
  color: red;
} */

/* .form-action-header>div{
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid red;
  border-radius: 50%;
  margin: 0px 5px;
  aspect-ratio: 1/1;
} */

.form-action-header .delete-btn {
  cursor: pointer;
}

label {
  margin-top: 20px;
}

select,
input {
  width: 100%;
  overflow: auto;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
}

.loader-component {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffffee;
  z-index: 9999;
}

.user-details {
  display: flex;
  flex-wrap: wrap;
}

.user-details p {
  flex: 50% 1;
}

