.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
}

.options-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.options-container > * {
  /* flex: 1; */
  margin: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: #aaa; */
  /* height: 100%; */
}

.main-container {
  display: flex;
  align-items: stretch;
  width: 100%;
}

.partition1 {
  width: 100%;
}

.partition2 {
  display: flex;
  align-items: center;
  width: fit-content;
}

.sample-images {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0px 0px 0px;
}

.sample-images img {
  margin: 10px auto;
}
