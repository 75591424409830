* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
}

.loader-component {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffffee;
  z-index: 9999;
}

.user-details {
  display: flex;
  flex-wrap: wrap;
}

.user-details p {
  flex: 50%;
}
